






































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import store from "@/store";
import { Message } from "element-ui";
import { ExportExcel } from "@/utils/exportFile";
@Component({})
export default class AUser extends Vue {
  // 字典
  config = store.state.system.config;
  // 表格数据
  tableData = [];
  // 加载状态
  loading = false;
  btnloading = false
  // 总条数
  total = 0;

  // 搜索条件
  search = {
    mobile: "",
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
  };
  // 搜索 search:true 重置翻页
  searchTable(search = false) {
    this.loading = true;
    if (search) {
      this.search.pagination = {
        pageSize: 20,
        page: 0,
      };
    }
    api
      .post("A29003", this.search)
      .then((data) => {
        this.total = data.total;
        this.tableData = data.list;

      })
      .finally(() => {
        this.loading = false;
      });
  }

  // 添加黑名单
  blackDialog = false
  mobile = ''
  add() {
    this.mobile = ''
    this.blackDialog = true
  }
  sure() {
    api.post('A29002', {mobile: this.mobile}).then(res => {
      this.blackDialog = false
      this.$message.success('操作成功')
      this.searchTable()
    })
  }
  cancle() {
    this.blackDialog = false
  }
  del(item: any) {
    this.$confirm('将删除该项, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      api.post('A29004', {mobile: item.mobile}).then(res => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
        this.searchTable()
      })

    })
  }
  activated() {
    this.searchTable();
  }

}
